import React, { useContext, useState } from 'react';
import axios from 'axios';

import links from '../resources/links';
import useForm, { FormInput } from '../hooks/useForm';

import Header from '../components/header/Header';
import Input from '../components/inputs/Input';
import Container from '../hoc/container/Container';
import Button from '../components/ui/button/Button';

import style from './contact.module.css';
import ReferralContext from '../contexts/ReferralContext';

const CONTACT_FORM_INPUTS: FormInput[] = [
	{ id: 'name', label: 'Your Name', isRequired: true },
	{ id: 'email', label: 'Your Email', isRequired: true },
	{ id: 'phone', label: 'Your Telephone', isRequired: true },
	{
		id: 'guests',
		label: 'Number of Guests',
		placeholder: 'How many guests would you like to accommodate?',
		isRequired: true,
	},
	{ id: 'dates', type: 'date', label: 'Dates', placeholder: 'When would you like to travel?', isRequired: true },
	{ id: 'location', label: 'Location(s)', placeholder: 'Where would you like to go?', isRequired: true },
	{ id: 'type', label: 'Type of Yacht', placeholder: 'Motor or Sail?', isRequired: false },
	{ id: 'length', label: 'Minimum Yacht Length', placeholder: 'Do you have a minimum boat length?', isRequired: false },
	{ id: 'specialRequests', label: 'Special Requests', placeholder: 'Do you have any special requests?', isRequired: false },
];

const ContactPage: React.FC = () => {
	const [formErrorMessage, setFormErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [hasSent, setHasSent] = useState(false);
	const referralId = useContext(ReferralContext);
	const { formFields, validateInput, createChangeHandler } = useForm(CONTACT_FORM_INPUTS);

	// const formFieldChanged = useCallback((e) => {
	// 	e.persist();
	// 	setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	// }, []);

	// Takes the
	const constructEmailMessage = () => {
		let message = '';
		Object.values(formFields).forEach((field) => {
			if (field.value) {
				message = message + field.id + ': ' + field.value + ', ';
			}
		});
		if (referralId) {
			message = message + `Referral: ${referralId}`;
		}
		// console.log(message);
		return message;
	};

	const validateInputs = async () => {
		const validArray = await Promise.all(Object.values(formFields).map(async ({ id }) => await validateInput(id)));
		return validArray.every((isValid) => isValid);
	};

	// TODO: This is duplicated in yacht.js - Create file for this.
	const sendMessage = async () => {
		setIsLoading(true);
		const isValid = await validateInputs();
		// console.log('isvalid;', isValid);
		if (!isValid) {
			return setIsLoading(false);
		}
		const message: string = constructEmailMessage();

		await axios
			.post('https://superior-yachting.herokuapp.com/email', {
				name: formFields.name.value,
				email: formFields.email.value,
				phone: formFields.phone.value,
				message: message,
			})
			.then((response) => {
				// console.log(response);
				if (response.data === 'success') {
					// Clear form data
					console.log('Email sent successfully');
					window.scrollTo(0, 0);
					setIsLoading(false);
					setHasSent(true);
				} else {
					setFormErrorMessage(
						'There was an error sending your message. Please try again or email us directly at info@superioryachting.com'
					);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				setFormErrorMessage(
					`There was an error sending your message. Please try again or email us directly at info@superioryachting.com. ${error}`
				);
				setIsLoading(false);
			});
	};

	return (
		<>
			<Header dataKey={links.contact.link} containerStyle={{ position: 'fixed', top: 0 }} showBackgroundOnly={true} />
			<Container containerClass={style.formContainer} large={true}>
				{!hasSent ? (
					<>
						<h2>Tell us about your trip and we'll get back to you shortly.</h2>
						<p>
							<span>Save 50% off your Broker fees with Superior Yachting.</span> For a limited time only.
						</p>
						{Object.values(formFields).map((input) => (
							<Input
								key={input.id}
								id={input.id}
								label={input.label}
								value={input.value}
								isValid={input.isValid}
								onChange={(e) => createChangeHandler(input.id)(e)}
							/>
						))}
						<Button
							id="submit-button"
							isOutlined={true}
							// isContained={true}
							isTertiary={true}
							label="SEND"
							isLoading={isLoading}
							onPress={sendMessage}
						/>
						{formErrorMessage && <span style={{ color: '#fff' }}>{formErrorMessage}</span>}
					</>
				) : (
					<h3>We look forward to hearing about your trip.</h3>
				)}
			</Container>
			<Container containerClass={style.contactDetails}>
				<a href="tel:44 20 3633 6723" title="Press to call">
					<h3 className={style.margin}>+ 44 20 3633 6723</h3>
				</a>
				<a href="mailto:info@superioryachting.com" title="Press to email us">
					<h3 className={style.margin}>info@superioryachting.com</h3>
				</a>
			</Container>
		</>
	);
};

export default ContactPage;

{
	/* <div className={style.social}>
					<a
						title="Connect with Superior Yachting on Facebook"
						target="_blank"
						href="https://www.facebook.com/superioryachting"
						rel="noopener noreferrer"
					>
						<div className={style.facebook} />
					</a>
					<a
						title="Follow Superior Yachting on Instagram"
						target="_blank"
						href="https://www.instagram.com/superior.yachting"
						rel="noopener noreferrer"
					>
						<div className={style.instagram} />
					</a>
				</div> */
}
