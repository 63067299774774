import React, { useState } from 'react';
import { FakeEvent } from '../components/inputs/Input';

export type FormInput = {
	id: string;
	label: string;
	type?: string;
	value?: string;
	placeholder?: string;
	options?: string[];
	isRequired?: boolean;
	isValid?: boolean;
	customValidation?: (value?: string) => boolean;
	customValidationAsync?: (value?: string) => Promise<boolean>;
};

const useForm = (inputs: FormInput[]) => {
	const [formFields, setFormFields] = useState(
		inputs.reduce((obj: { [key: string]: FormInput }, item) => ((obj[item.id] = item), obj), {})
	);

	const createChangeHandler = (key: string) => async (e: React.ChangeEvent<HTMLInputElement> | FakeEvent) => {
		const value = e.target.value;
		const isValid = await checkIfValid(key, value);
		setFormFields((prev) => {
			return { ...prev, [key]: { ...prev[key], value, isValid } };
		});
	};

	const checkIfValid = async (key: string, newValue?: string) => {
		const input = newValue ? { ...formFields[key], value: newValue } : formFields[key];
		if (!input.isRequired && !input.value) return true;

		let isValid = input.value !== undefined && input.value !== null && input.value !== '';
		if (input.customValidation) {
			isValid = input.customValidation(input.value);
		} else if (input.customValidationAsync) {
			isValid = await input.customValidationAsync(input.value);
		}

		// Not sure how to handle confirm password custom validation
		if (input.id === 'passwordConfirm') {
			isValid = input.value === formFields['password'].value;
		}

		return isValid;
	};

	const validateInput = async (key: string) => {
		const isValid = await checkIfValid(key);
		setFormFields((prev) => ({ ...prev, [key]: { ...prev[key], isValid } }));
		return isValid;
	};

	return { formFields, validateInput, createChangeHandler };
};

export default useForm;
