import React from 'react';
// import ToggleInput from './toggle/ToggleInput';
import TextField from '../ui/input/textField/TextField';
import style from './Input.module.css';

export type FakeEvent = { target: { value: string } };

type InputProps = {
	id: string;
	label: string;
	type?: string;
	value?: string;
	placeholder?: string;
	options?: string[];
	isRequired?: boolean;
	isValid?: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement> | FakeEvent) => void;
	onBlur?: () => void;
};

const Input = ({ id, label, type, value, options, isRequired, isValid, onChange, onBlur }: InputProps) => (
	<div className={style.container}>
		{/* {type === 'toggle' ? (
			<ToggleInput
				id={id}
				options={options}
				value={value}
				isValid={isValid}
				onChange={onChange}
				onBlur={onBlur ? onBlur : () => {}}
			/>
		) : ( */}
		<TextField
			name={id}
			label={label}
			value={value}
			onChange={onChange}
			isValid={isValid}
			onBlur={onBlur ? onBlur : () => {}}
		/>
		{/* )} */}
	</div>
);

export default Input;

{
	/* <input
				className={style.normalRegular}
				id={id}
				type={type}
				placeholder={placeholder}
				value={value}
				required={isRequired}
				onChange={onChange}
				onBlur={onBlur}
				style={{ borderColor: isValid || isValid === undefined ? '#e1dfdd' : 'red' }}
			/> */
}
